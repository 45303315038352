import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Distribution`}</p>
    <p>{`ERPNext for Manufacturing Companies
ERPNext Manufacturing module helps you manage your manufacturing process right from procurement to shipping finished goods.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Bill of Materials
Manage multi-level Bill of Materials and Operations, the blue print of your manufacturing system.
Production Plan
Make Production Plans based on Sales Orders or internal requirements and track operations.
Work Order
Issue and track Work Orders for workstations and plan time and material for each Work Order.
Items and Variants
ERPNext Item management helps you create and manage your manufacturing items and its variants.
Batches and Serial Numbers
ERPNext has built-in support for batched and serialized inventory that will help you in traceability.
Quality Inspection
Keep track of quality inspections done for purchased and manufacturing materials.`}</p>
    <p>{`ERPNext for Distribution or Trading
ERPNext is a perfect fit for distribution companies and helps you manage your inventory across multiple currencies and companies.`}</p>
    <p>{`Try ERPNext free for 14 days`}</p>
    <p>{`Inventory
ERPNext stock module will help you manage inventory and procurement across warehouses.
Billing and Payments
Track all things financial, including billings and payments with the ERPNext accounts module.
Items and Variants
ERPNext Item management helps you create and manage your manufacturing items and its variants.
Multi Currency
You can maintain prices, bills, track payments and maintain account balances in multiple currencies.
Batches and Serial Numbers
ERPNext has built-in support for batched and serialized inventory that will help you in traceability.
Quality Inspection
Keep track of quality inspections done for purchased and manufacturing materials.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      